import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { PrimeReactContext } from 'primereact/api'
import { Toast } from 'primereact/toast'

export const LayoutContext = createContext({})

export const LayoutProvider = ({ children, user = null, impersonated_by = null }) => {

    const toast = useRef(null)

    const { changeTheme } = useContext(PrimeReactContext)

    const viewingAs = impersonated_by ? user : {}
    user = impersonated_by ? impersonated_by : user

    // debugger
    const initialState = {
        ripple: false,
        inputStyle: 'outlined',
        menuMode: 'overlay',
        colorScheme: localStorage.theme ?? 'light',
        theme: `/themes/${localStorage.theme ?? 'light'}.css`,
        scale: 13,
        viewingAs,
        user,
    }

    // const { user = null } = usePage().props
    // console.log(user)

    const [globalState, setGlobalState] = useState(initialState)

    useEffect(() => {
        // console.log('render', globalState)
    }, [globalState])


    const [layoutState, setLayoutState] = useState({
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,

        staticMenuMobileActive: false,
        menuHoverActive: false,

        notificationsVisible: false,

        explainerVisible: false,
        explainerElement: '',

        imposterDialogVisible: false,
        switchingUser: false,

        languageDialogVisible: false,
        languageLoading: false,

        feedbackDialogVisible: false,

        communicationChooserDialogVisible: false,

        eventlogDialogVisible: false,
        eventlogEventClass: '',
        eventlogEventItem: '',
    })

    const onMenuToggle = () => {
        if (isOverlay()) {
            setLayoutState((prevLayoutState) => ({
                ...prevLayoutState,
                overlayMenuActive: !prevLayoutState.overlayMenuActive,
            }))
        }

        if (isDesktop()) {
            setLayoutState((prevLayoutState) => ({
                ...prevLayoutState,
                staticMenuDesktopInactive: !prevLayoutState.staticMenuDesktopInactive,
            }))
        } else {
            setLayoutState((prevLayoutState) => ({
                ...prevLayoutState,
                staticMenuMobileActive: !prevLayoutState.staticMenuMobileActive,
            }))
        }
    }

    const showProfileSidebar = () => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            profileSidebarVisible: !prevLayoutState.profileSidebarVisible,
        }))
    }

    const showExplainer = (element) => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            explainerVisible: true,
            explainerElement: element,
        }))
    }

    const showNotifications = () => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            notificationsVisible: true,
        }))
    }


    const showLanguageDialog = (state) => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            languageDialogVisible: state,
        }))
    }

    const showImposterDialog = (state) => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            imposterDialogVisible: state,
        }))
    }

    const showFeedbackDialog = (state) => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            feedbackDialogVisible: state,
        }))
    }


    const showCommunicationChooserDialog = (state) => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            communicationChooserDialogVisible: state,
        }))
    }


    const showEventLogDialog = (state, feature) => {
        let type, uuid = ''

        if (feature?.data) {
            type = feature.data.type
            uuid = feature.data.uuid

            switch (type) {
                case 'Organisation':
                    return null
                case 'boundary_site':
                case 'boundary_control':
                    type = 'Site'
                    break
                case 'sensor_treatment':
                case 'sensor_control':
                case 'sensor_edge':
                    type = 'Sensor'
                    break
            }
        }

        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            eventlogDialogVisible: state,
            eventlogEventClass: type,
            eventlogEventItem: uuid,
        }))
    }

    const setLanguageLoading = (state) => {
        setLayoutState((prevLayoutState) => ({
            ...prevLayoutState,
            languageLoading: state,
        }))
    }

    const isOverlay = () => {
        return globalState.menuMode === 'overlay'
    }

    const isDesktop = () => {
        return window.innerWidth > 991
    }

    const darkMode = (forceTheme = '') => {
        let theme = (!localStorage.theme || localStorage.theme === 'light') ? '/themes/dark.css' : '/themes/light.css'
        let _colorScheme = (!localStorage.theme || localStorage.theme === 'light') ? 'dark' : 'light'
        if (forceTheme) {
            _colorScheme = forceTheme
            theme = `/themes/${forceTheme}.css`
        }

        changeTheme(localStorage.theme, _colorScheme, 'theme-css', () => {
            localStorage.setItem('theme', _colorScheme)
            setGlobalState((prevState) => ({
                ...prevState,
                theme,
                colorScheme: _colorScheme,
            }))
        })
    }

    const decrementScale = () => {
        setGlobalState((prevState) => ({ ...prevState, scale: prevState.scale - 1 }))
    }

    const incrementScale = () => {
        setGlobalState((prevState) => ({ ...prevState, scale: prevState.scale + 1 }))
    }

    const applyScale = () => {
        document.documentElement.style.fontSize = globalState.scale + 'px'
    }

    const acknowledgeMessage = (message) => {
        const lsa = localStorage.getItem('acknowledgements') || '[]'
        const acknowledgements = JSON.parse(lsa)
        if (!acknowledgements.includes(message.id)) {
            acknowledgements.push(message.id)
            localStorage.setItem('acknowledgements', JSON.stringify(acknowledgements.filter(Boolean)))
        }
    }

    const messageAcknowledged = (message) => {
        const acknowledgements = localStorage.getItem('acknowledgements') || []
        return acknowledgements.includes(message)
    }

    const clearAcknowledgements = (showToast = true) => {
        localStorage.removeItem('acknowledgements')
        if (showToast) {
            toast.current.show({
                severity: 'success',
                summary: 'Confirmed',
                detail: 'Warnings reset',
                life: 3000,
            })
        }
    }

    /**
     * View as...
     * @param viewAs
     */
    const initSwitch = (viewAs) => {
        setLayoutState((prevState) => ({ ...prevState, switchingUser: true }))

        if (viewAs) {

            axios.get('/admin/viewAs/' + viewAs.uuid).then(
                res => {
                    setGlobalState((prevState) => ({ ...prevState, viewingAs: res.data }))
                    window.location.reload()
                    setTimeout(() => {
                        setLayoutState((prevState) => ({ ...prevState, switchingUser: false }))
                        showImposterDialog(false)
                        const lastname = res.data.lastname ?? ''
                        const name = res.data.firstname + ' ' + lastname
                        toast.current.show({
                            severity: 'info',
                            summary: 'Confirmed',
                            detail: 'Please hold: switching to user ' + name,
                            life: 3000,
                        })

                    }, 1000)
                },
            )
        } else {

            axios.get('/admin/viewAs/').then(
                res => {

                    setGlobalState((prevState) => ({ ...prevState, viewingAs: {} }))
                    window.location.reload()
                    setTimeout(() => {
                        setLayoutState((prevState) => ({ ...prevState, switchingUser: false }))
                        showImposterDialog(false)
                        toast.current.show({
                            severity: 'info',
                            summary: 'Confirmed',
                            detail: 'Please hold: switching back to yourself',
                            life: 3000,
                        })
                    }, 1000)
                },
            )


        }

    }

    const value = {
        globalState,
        setGlobalState,
        layoutState,
        setLayoutState,
        onMenuToggle,

        darkMode,
        decrementScale,
        incrementScale,
        applyScale,

        // showSearch,
        // setSearch,
        // setSearching,
        // setSearchResults,

        showNotifications,

        initSwitch,
        showProfileSidebar,
        showExplainer,
        showLanguageDialog,
        showImposterDialog,
        showFeedbackDialog,
        showEventLogDialog,
        showCommunicationChooserDialog,
        setLanguageLoading,

        acknowledgeMessage,
        messageAcknowledged,
        clearAcknowledgements,
    }

    return <>
        <Toast ref={toast} />
        <link
            id='theme-css'
            href={globalState.theme}
            rel='stylesheet' />
        <LayoutContext.Provider value={value}>
            {children}
        </LayoutContext.Provider>
    </>
}
