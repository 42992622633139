import './bootstrap'
import { createRoot } from 'react-dom/client'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { LayoutProvider } from '@/Layouts/layout/context/layoutcontext'
import { IntlProviderWrapper } from '@/Layouts/layout/context/intlcontext'
import { createInertiaApp, router } from '@inertiajs/react'
import { PrimeReactProvider } from 'primereact/api'
import * as Sentry from '@sentry/react'

Sentry.init({
    dsn: 'https://32ab7fabdd12335769a6ed99666bc911@o4506970747895808.ingest.us.sentry.io/4506970750648320',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    trackComponents: true,
    logErrors: true,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/app.faunaphotonics.com/, /^https:\/\/app-preprod.faunaphotonics.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

if (window.location.host === 'app.faunaphotonics.com') {


    window.dataLayer = window.dataLayer || []

    function gtag() {
        dataLayer.push(arguments)
    }

    router.on('navigate', (event) => {
        gtag('event', 'page_view', {
            page_location: event.detail.page.url,
            // page_title: '[REPLACE WITH PAGE TITLE]',
        })
    })


}

createInertiaApp({
    title: (title) => `${title}`,// - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {

        const root = createRoot(el)
        // MD5 of latest revision of translations
        // Initially logged in User
        const { lang_md5, user, impersonated_by } = props.initialPage.props
        root.render(
            <PrimeReactProvider>
                <LayoutProvider
                    user={user}
                    impersonated_by={impersonated_by}>
                    <IntlProviderWrapper lang_md5={lang_md5}>
                        <App {...props} />
                    </IntlProviderWrapper>
                </LayoutProvider>
            </PrimeReactProvider>,
        )
    },
    // progress: {
    //     color: '#4B5563',
    // },
})
